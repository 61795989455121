import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "./theme"

import { gsap } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"

gsap.registerPlugin(CSSPlugin)

interface Props {
  element?: any
}

const wrapRootElement: React.FC<Props> = ({ element }: Props) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}

export default wrapRootElement
