import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useState, useEffect } from "react"
import { isBrowser } from "react-device-detect"
import styled from "styled-components"

import bg from "../../images/player/splash.png"
import { GradientText } from "../elements"

interface Props {
  active: boolean
  ready: boolean
  onDismiss: () => void
}

const Loader: React.FC<Props> = ({
  active = true,
  ready = false,
  onDismiss,
}: Props) => {
  const [imgLoaded, setImageLoaded] = useState(false)
  const [ie, setIe] = useState(false)
  useEffect(() => {
    const isIE =
      !!window.navigator.userAgent.match(/Trident/g) ||
      !!window.navigator.userAgent.match(/MSIE/g)
    if (isIE) {
      setIe(true)
    }
  }, [])
  const data = useStaticQuery(graphql`
    query {
      splash: file(relativePath: { eq: "player/splash.png" }) {
        childImageSharp {
          fluid(maxWidth: 2109, maxHeight: 1406) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleDismiss = (): void => {
    onDismiss()
  }

  const renderAnimatedBg = (): any => {
    if (isBrowser) {
      return <Background imgLoaded={imgLoaded} />
    } else {
      return null
    }
  }

  return (
    <Container active={active}>
      <Splash
        fluid={data.splash.childImageSharp.fluid}
        onLoad={(): void => setImageLoaded(true)}
        alt={`Loading image splash`}
      />
      {renderAnimatedBg()}
      <svg>
        <filter id="turbulence" x="0" y="0" width="100%" height="100%">
          <feTurbulence
            id="water-filter"
            numOctaves="3"
            seed="2"
            baseFrequency="0.02 0.05"
          ></feTurbulence>
          <feDisplacementMap scale="20" in="SourceGraphic"></feDisplacementMap>
          <animate
            xlinkHref="#water-filter"
            attributeName="baseFrequency"
            dur="60s"
            keyTimes="0;0.5;1"
            values="0.02 0.06;0.04 0.08;0.02 0.06"
            repeatCount="indefinite"
          />
        </filter>
      </svg>
      <Content>
        {ready ? (
          <>
            {ie ? (
              <p className="ie-text">Watch stories of courage</p>
            ) : (
              <GradientText key="loaded" text="Watch stories of courage" />
            )}
            <PlayIcon
              onClick={handleDismiss}
              src={require("../../images/icons/play.svg")}
              alt="Play"
            />
          </>
        ) : (
          <>
            {ie ? (
              <p className="ie-text">Loading</p>
            ) : (
              <GradientText key="loading" text="Loading" />
            )}
          </>
        )}
      </Content>
    </Container>
  )
}

export default Loader

const Container = styled.div<{ active: boolean }>`
  position: absolute;
  z-index: 8;
  width: 100%;
  height: 100%;
  transition: all ease 600ms;
  overflow: hidden;
  opacity: ${(props): any => (props.active ? 1 : 0)};
  pointer-events: ${(props): any => (props.active ? "all" : "none")};
`

const Content = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--gutter-s);
  .ie-text {
    color: #fff;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 12px;
    font-weight: 300;
  }
`

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50px, -50px);
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  pointer-events: none;
  background-image: url(${bg});
  background-position: center center;
  background-size: cover;
  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.imgLoaded ? 1 : 0)};
  filter: ${(props) => (props.imgLoaded ? `url("#turbulence")` : `none`)};
`

const Splash = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  transform: translate(-50px, -50px);
  object-fit: cover;
  object-position: center center;
  /* filter: url("#turbulence"); */
`

const PlayIcon = styled.img`
  width: 60px;
  height: 60px;
  transition: transform 0.25s ease;
  @media (min-width: 768px) {
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
`
