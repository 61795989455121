import React from "react"
import Wrapper from "./Wrapper"

interface Props {
  color?: string
  width?: number
  height?: number
}

const FaceBook: React.FC<Props> = ({
  color = "#ffffff",
  width = 50,
  height,
  ...props
}: Props) => {
  return (
    <Wrapper
      color={color}
      width={width}
      height={height}
      viewbox="0 0 285.45 281.3"
      {...props}
    >
      <g className="fill">
        <path
          d="M162.25,91.01h20.66V60.49h-24.29v0.11c-29.43,1.04-35.46,17.58-35.99,34.96h-0.06v15.24h-20.04v29.89h20.04
	v80.12h30.2v-62.29v-8.35v-9.48h24.74l4.78-29.89h-29.51v-9.21C152.78,95.72,156.69,91.01,162.25,91.01L162.25,91.01z M162.25,91.01
	"
        />
      </g>
    </Wrapper>
  )
}

export default FaceBook
