import React, { useState, useEffect, useRef, useCallback } from "react"
import styled from "styled-components"
import { ROWER_DATA } from "./data"
import RowerList from "./RowerList"
import RowerInfo from "./RowerInfo"
import Social from "./Social"
import _ from "lodash"

import Loadable from "@loadable/component"
const RowerBackground = Loadable(() => import("./RowerBackground"))

const DesktopRowers: React.FC<{}> = () => {
  const activeRowerRef = useRef(0)
  const [activeRower, setActiveRower] = useState(0)

  const handleNext = useCallback((): void => {
    if (activeRowerRef.current >= 6) {
      activeRowerRef.current = 0
      setActiveRower(0)
    } else {
      activeRowerRef.current += 1
      setActiveRower(activeRowerRef.current)
    }
  }, [])

  const handlePrev = useCallback((): void => {
    if (activeRowerRef.current <= 0) {
      activeRowerRef.current = 6
      setActiveRower(6)
    } else {
      activeRowerRef.current -= 1
      setActiveRower(activeRowerRef.current)
    }
  }, [])

  useEffect(() => {
    const Loader = require("pixi.js").Loader

    const preLoader = Loader.shared
    preLoader.reset()
    ROWER_DATA.map((rower, index) => {
      if (index !== 0) {
        const key = index + ""
        preLoader.add(key, rower.image)
      }
    })
    preLoader.load()
  }, [])

  useEffect(() => {
    let touchStartY = 0

    const handleScroll = _.throttle(
      (event: any): void => {
        if (event.deltaY >= 0) {
          handleNext()
        } else {
          handlePrev()
        }
      },
      1500,
      { leading: true, trailing: false }
    )

    const handleTouchStart = (event: any): void => {
      touchStartY = event.touches[0].clientY
    }

    const handleTouchEnd = (event: any): void => {
      const touchEndY = event.changedTouches[0].clientY
      if (touchStartY > touchEndY) {
        handleNext()
      } else if (touchStartY === touchEndY) {
        return
      } else {
        handlePrev()
      }
    }

    window.addEventListener("touchstart", handleTouchStart)
    window.addEventListener("touchend", handleTouchEnd)
    window.addEventListener("wheel", handleScroll)

    return (): void => {
      window.removeEventListener("touchstart", handleTouchStart)
      window.removeEventListener("touchend", handleTouchEnd)
      window.removeEventListener("wheel", handleScroll)
    }
  }, [])

  const handleRowerSelect = (e: any): void => {
    setActiveRower(e)
  }

  return (
    <>
      <RowerBackground activeBackground={ROWER_DATA[activeRower].image} />
      <ContentContainer>
        <RowerList
          data={ROWER_DATA}
          activeRower={activeRower}
          select={handleRowerSelect}
        />
        <RowerInfo activeRower={activeRower} data={ROWER_DATA} />
        <Social activeRower={activeRower} data={ROWER_DATA} />
      </ContentContainer>
    </>
  )
}

const ContentContainer = styled.div`
  min-height: 100vh;
  @media (min-width: 768px) {
    display: flex;
    height: 100vh;
  }
`

export default DesktopRowers
