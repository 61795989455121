import { Link } from "gatsby"
import _ from "lodash"
import moment from "moment"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import YouTube from "react-youtube"
import { Options } from "react-youtube"
import styled from "styled-components"

import { useLocation } from "@reach/router"

import EPISODES from "../../constants/episodes"
import { useIdleTimer } from "../../hooks"
import Header from "../Layout/Header"
import SEO from "../SEO"
import { Button, GradientText } from "../elements"
import Loader from "./Loader"
import Seeker from "./Seeker"
import ShareModal from "./ShareModal"

const DEFAULT_VIDEO_OPTIONS = {
  playerVars: {
    autoplay: 0,
    controls: 0,
    disablekb: 1,
    enablejsapi: 1,
    playsinline: 1,
    fs: 0,
    listType: "user_uploads",
  },
}

type OptionsState = Options | null

const Documentary: React.FC = () => {
  const videoRef = useRef<any>(null)
  const videoContainerRef = useRef<any>(null)
  const pauseOverlayRef = useRef<HTMLDivElement>(null)
  const [duration, setDuration] = useState(0)
  const [loaderActive, setLoaderActive] = useState(true)
  const [videoOptions, setVideoOptions] = useState<OptionsState>(null)
  const [videoReady, setVideoReady] = useState(false)
  const [muted, setMuted] = useState(false)
  const [subtitlesOn, setSubtitlesOn] = useState(false)
  const [progress, setProgress] = useState(0)
  const [shareModalActive, setShareModalActive] = useState(false)
  const [currentEpisode, setCurrentEpisode] = useState<any | null>()
  const [showShare, setShowShare] = useState(false)
  const [shareDuration, setShareDuration] = useState(0)
  const progressIntervalRef = useRef<any>(null)
  const { state }: any = useLocation()
  const isIdle = useIdleTimer()
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [videoHeight, setVideoHeight] = useState(0)

  const [youtubeVideo, setToutubeVideo] = useState(
    process.env.YOUTUBE_DOCUMENTARY_VIDEO_ID
  )

  const handleScreenSize = (): void => {
    setVideoHeight(window.innerHeight)
  }

  useEffect(() => {
    setVideoHeight(window.innerHeight)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleScreenSize)
  }, [])

  useEffect(() => {
    if (isMobile) {
      setToutubeVideo(process.env.YOUTUBE_DOCUMENTARY_VIDEO_ID_MOBILE)
    }
  }, [])

  const currentTime = moment.duration(
    Math.round((duration * progress) / 100),
    "seconds"
  )

  const updateProgress = useCallback(() => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current.playerInfo
      setProgress((currentTime / duration) * 100)

      const currEpisode = _.findLast(EPISODES, (episode) => {
        return episode.time <= currentTime
      })

      if (currEpisode) {
        setCurrentEpisode(currEpisode)
      } else {
        setCurrentEpisode(null)
      }

      const sharedEpisode = _.findLast(
        EPISODES,
        (episode) =>
          episode.shareTimeStart < currentTime &&
          episode.shareTimeEnd > currentTime
      )

      if (sharedEpisode && !showShare) {
        setShareDuration(sharedEpisode.shareTimeEnd - currentTime)
        setShowShare(true)
      } else if (!sharedEpisode) {
        setShareDuration(0)
        setShowShare(false)
      }
    }
  }, [showShare])

  const handlePlay = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.playVideo()
      setIsPlaying(true)
      setIsPaused(false)
    }
  }, [videoRef])

  const handlePause = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pauseVideo()
      setIsPlaying(false)
      setIsPaused(true)
    }
    if (pauseOverlayRef.current) {
      pauseOverlayRef.current.classList.add("active")
    }
  }, [videoRef, pauseOverlayRef])

  const handleCC = useCallback(() => {
    if (videoRef.current) {
      if (!subtitlesOn) {
        videoRef.current.loadModule("cc")
        videoRef.current.loadModule("captions")
        videoRef.current.setOption("captions", "track", { languageCode: "en" }) //Works for html5 ignored by AS3
        videoRef.current.setOption("cc", "track", { languageCode: "en" }) //Works for AS3 ignored by html5
        setSubtitlesOn(true)
      } else {
        videoRef.current.unloadModule("cc")
        videoRef.current.unloadModule("captions")
        setSubtitlesOn(false)
      }
    }
  }, [subtitlesOn])

  const handleMute = useCallback(() => {
    if (videoRef.current?.playerInfo?.muted) {
      setMuted(false)
      videoRef.current.unMute()
    } else {
      setMuted(true)
      videoRef.current.mute()
    }
  }, [videoRef])

  const handleResume = useCallback(() => {
    if (pauseOverlayRef.current) {
      pauseOverlayRef.current.classList.remove("active")
      handlePlay()
    }
  }, [handlePlay])

  const handleReset = useCallback(() => {
    videoRef.current.seekTo(0)
    handleResume()
    setProgress(0)
    setCurrentEpisode(null)
  }, [handleResume])

  const handleSeek = useCallback((progress) => {
    if (videoRef.current) {
      videoRef.current.seekTo(
        (videoRef.current.playerInfo.duration * progress) / 100
      )
      setProgress(progress)
    }
  }, [])

  const togglePlay = useCallback(() => {
    if (videoRef.current) {
      // If video is playing
      if (videoRef.current.playerInfo.playerState === 1) {
        handlePause()
      } else {
        handleResume()
      }
    }
  }, [videoRef, handlePause, handleResume])

  const onReady = useCallback(
    (event) => {
      const time = state?.time ?? null
      event.target.hideVideoInfo()
      videoRef.current = event.target
      if (time) {
        event.target.seekTo(time)
        event.target.playVideo()
        setLoaderActive(false)
      } else {
        event.target.pauseVideo()
      }

      if (videoRef.current?.f ?? null) {
        videoContainerRef.current = videoRef.current.f.parentElement
        videoContainerRef.current.addEventListener("click", togglePlay)
      }

      setVideoReady(true)
      setDuration(event.target.playerInfo.duration)

      progressIntervalRef.current = window.setInterval(() => {
        updateProgress()
      }, 1000)
    },
    [updateProgress, state, togglePlay]
  )

  useEffect(() => {
    setVideoOptions({
      ...DEFAULT_VIDEO_OPTIONS,
      width: `${window.innerWidth}`,
      height: `${window.innerHeight}`,
    } as OptionsState)

    const handleSpacePress = (e: any): void => {
      if (videoRef.current && e.code === "Space") {
        e.preventDefault()
        togglePlay()
      }
    }

    window.addEventListener("keydown", handleSpacePress)

    return () => {
      window.removeEventListener("keydown", handleSpacePress)
      if (progressIntervalRef.current) {
        window.clearInterval(progressIntervalRef.current)
      }

      if (videoContainerRef.current) {
        videoContainerRef.current.removeEventListener("click", togglePlay)
      }
    }
  }, [handlePause, handleResume, togglePlay])

  const handleLoaderDismiss = useCallback(() => {
    setLoaderActive(false)
    if (videoRef.current) {
      videoRef.current.playVideo()
      setIsPlaying(true)
    }
  }, [])

  const handleShare = useCallback(() => {
    if (showShare) {
      handlePause()
      setShareModalActive(true)
    }
  }, [handlePause, showShare])

  const onShareDismiss = useCallback(() => {
    handleResume()
    setShareModalActive(false)
  }, [handleResume])

  const handleNavOpen = (e: boolean): void => {
    if (e) {
      handlePause()
    }
  }

  useEffect(() => {
    if (isPlaying && pauseOverlayRef.current) {
      pauseOverlayRef.current.classList.remove("active")
    }
  }, [isPlaying, pauseOverlayRef])

  return (
    <>
      <SEO
        image={state?.image}
        title={state?.title}
        description={state?.description}
      />
      {videoReady && (
        <Header
          handleNavOpen={handleNavOpen}
          isPlaying={isPlaying}
          isIdle={isIdle}
          handleReset={handleReset}
          paused={isPaused}
        />
      )}
      <DocumentaryContainer height={videoHeight}>
        <Loader
          active={loaderActive}
          ready={videoReady}
          onDismiss={handleLoaderDismiss}
        />
        {videoOptions && (
          <>
            <YouTube
              containerClassName="youtube-video"
              videoId={youtubeVideo}
              opts={videoOptions}
              onReady={onReady}
            />
            <Controls className={isIdle && !showShare ? "idle" : ""}>
              <Seeker
                totalDuration={duration}
                progress={progress}
                onSeek={handleSeek}
                onShare={handleShare}
                showShare={showShare}
                showShareInstructions={currentEpisode?.number == 1}
                shareDuration={shareDuration}
              />
              <Actions>
                <div>
                  <Icon
                    onClick={handlePause}
                    src={require("../../images/icons/pause.svg")}
                  />
                  {subtitlesOn ? (
                    <Icon
                      onClick={handleCC}
                      src={require("../../images/icons/cc-2.svg")}
                    />
                  ) : (
                    <Icon
                      onClick={handleCC}
                      src={require("../../images/icons/cc.svg")}
                    />
                  )}
                </div>
                {muted ? (
                  <Icon
                    onClick={handleMute}
                    src={require("../../images/icons/sound-off.svg")}
                    className="sound-off"
                  />
                ) : (
                  <Icon
                    onClick={handleMute}
                    src={require("../../images/icons/sound-on.svg")}
                  />
                )}
              </Actions>
            </Controls>
          </>
        )}
      </DocumentaryContainer>
      <PauseOverlay ref={pauseOverlayRef}>
        <GradientText
          className="paused"
          fontSize={20}
          text={`Paused | ${currentTime.get("minutes")}’${String(
            currentTime.get("seconds")
          ).padStart(2, "0")}”`}
        />
        <ChapterText>
          {currentEpisode?.title || "THE GOLDEN STANDARD"}
        </ChapterText>
        <Button
          icon={require("../../images/icons/play-gold.svg")}
          text="Resume documentary"
          onClick={handleResume}
        />
        <RestartButton onClick={handleReset}>
          <img src={require("../../images/icons/replay.svg")} />
          <span>restart documentary</span>
        </RestartButton>

        <ButtonLinkContainer>
          <IconLinkButtons to="/episodes/">
            <img src={require("../../images/icons/episodes.svg")} />
            <span>Episodes</span>
          </IconLinkButtons>
          <IconLinkButtons to="/rowers/">
            <img src={require("../../images/icons/paddles.svg")} />
            <span>Rowing heroes</span>
          </IconLinkButtons>
        </ButtonLinkContainer>
      </PauseOverlay>
      <ShareModal
        episode={currentEpisode}
        active={shareModalActive}
        onDismiss={onShareDismiss}
      />
    </>
  )
}

export default Documentary

const DocumentaryContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .youtube-video {
    iframe {
      width: 100vw;
      height: ${(props) => `${props.height}px` || "100vh"};
      pointer-events: none;
    }
  }
`

const Controls = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  opacity: 1;
  transform: translate(0%, 0%);
  transition: all ease 200ms;
  &.idle {
    opacity: 0;
    transform: translate(0%, 50%);
    transition: all ease 500ms;
  }
`

const Actions = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px 25px 15px;
  @media (min-width: 768px) {
    padding: 0 25px 25px 25px;
  }
`

const Icon = styled.img`
  width: ${(props): any => props.width || 38}px;
  height: ${(props): any => props.height || 38}px;
  transition: opacity 0.25s ease;
  &.sound-off {
    margin-right: 4px;
  }
  @media (min-width: 768px) {
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`

const PauseOverlay = styled.div`
  padding: 0 15px;
  padding-top: var(--gutter-xl);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0;
  pointer-events: none;
  z-index: 5;
  transition: opacity ease 350ms;
  min-height: 100%;
  &.active {
    pointer-events: all;
    opacity: 1;
  }
`

const RestartButton = styled.button`
  font-size: 12px;
  font-weight: 300;
  margin-top: var(--gutter-s);
  img,
  span {
    transition: all 0.5s ease;
  }
  img {
    display: inline-block;
    height: 25px;
    vertical-align: middle;
    margin-right: 10px;
  }
  span {
    display: inline-block;
    margin-top: 2px;
    vertical-align: middle;
    letter-spacing: 1px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 1px;
      width: 100%;
      opacity: 0;
      transition: all 0.5s ease;
    }
  }
  @media (min-width: 768px) {
    &:hover {
      cursor: pointer;
      img {
        transform: rotate(-360deg);
      }
      span {
        opacity: 0.6;
        position: relative;
        &:after {
          background-color: #fff;
          opacity: 1;
        }
      }
    }
  }
`

const ChapterText = styled.h2`
  text-transform: uppercase;
  font-family: "RMB-Sans-ExtraBold";
  font-weight: normal;
  letter-spacing: 0.05em;
  font-size: 30px;
  margin: 30px 0px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 50px;
  }
`

const ButtonLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: var(--gutter-m);
  @media (min-width: 768px) {
    margin-top: var(--gutter-l);
    flex-direction: row;
  }
`

const IconLinkButtons = styled(Link)`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  margin-top: var(--gutter-s);
  transition: all ease 300ms;
  border: 1px solid transparent;
  min-width: 150px;
  margin: 10px 0;
  padding: 8px;
  text-align: center;
  img {
    display: inline-block;
    height: 25px;
    vertical-align: middle;
    margin-right: 10px;
  }
  span {
    margin-top: 5px;
    vertical-align: middle;
    display: inline-block;
  }
  @media (min-width: 768px) {
    padding: 12px;
    margin: 10px;
    img {
      height: 30px;
    }
    &:hover {
      border: 1px solid var(--gold);
    }
  }
`
