import React from "react"
import styled from "styled-components"

interface Props {
  color?: string
  width?: number
  height?: number
  children: any
  viewbox: string
}

const Wrapper: React.FC<Props> = ({
  color = "#ffffff",
  width = 50,
  height,
  children,
  viewbox,
  ...props
}: Props) => {
  return (
    <SVG
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox={viewbox}
      color={color}
      width={width}
      height={height}
      {...props}
    >
      {children}
    </SVG>
  )
}

export default Wrapper

const SVG = styled.svg`
  .fill {
    transition: fill ease 300ms;
    fill: transparent;
  }

  * {
    stroke: ${(props): any => props.color};
    stroke-width: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  &:hover {
    .fill {
      fill: ${(props): any => props.color};
    }
  }
`
