import React from "react"
import Wrapper from "./Wrapper"

interface Props {
  color?: string
  width?: number
  height?: number
}

const Twitter: React.FC<Props> = ({
  color = "#ffffff",
  width = 50,
  height,
  ...props
}: Props) => {
  return (
    <Wrapper
      color={color}
      width={width}
      height={height}
      viewbox="0 0 285.45 281.3"
      {...props}
    >
      <g className="fill">
        <path
          d="M220.95,92.13c-5.75,2.55-11.95,4.28-18.43,5.05c6.63-3.98,11.71-10.26,14.12-17.76
	c-6.21,3.67-13.08,6.35-20.39,7.79c-5.86-6.24-14.2-10.14-23.44-10.14c-17.73,0-32.1,14.38-32.1,32.1c0,2.51,0.28,4.97,0.83,7.32
	c-26.67-1.34-50.33-14.12-66.16-33.55c-2.77,4.75-4.35,10.26-4.35,16.14c0,11.14,5.67,20.97,14.27,26.72
	c-5.26-0.16-10.2-1.61-14.54-4.02c0,0.14,0,0.27,0,0.41c0,15.55,11.07,28.52,25.75,31.47c-2.69,0.74-5.53,1.13-8.45,1.13
	c-2.07,0-4.08-0.2-6.04-0.57c4.09,12.75,15.95,22.03,30,22.29c-10.99,8.62-24.83,13.74-39.87,13.74c-2.6,0-5.16-0.14-7.66-0.44
	c14.2,9.11,31.07,14.42,49.2,14.42c59.04,0,91.34-48.92,91.34-91.34c0-1.39-0.04-2.77-0.09-4.15
	C211.22,104.21,216.65,98.56,220.95,92.13L220.95,92.13z M220.95,92.13"
        />
      </g>
    </Wrapper>
  )
}

export default Twitter
