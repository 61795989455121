import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

export interface Props {
  activeRower: any
  data: any
}

const RowerInfo: React.FC<Props> = ({ activeRower, data }: Props) => {
  const [show, setShow] = useState(false)
  const [bio, setBio] = useState([])
  const [gender, setGender] = useState("")
  const [chapter, setChapter] = useState(0)
  const [image, setImage] = useState(null)

  useEffect(() => {
    if (data) {
      data.map((item: any, i: any) => {
        if (i === activeRower) {
          setBio(item.rowerBio)
          setChapter(item.videoChapter)
          setImage(item.profileImg)
          if (i === 0 || i === 2 || i === 4 || i === 5) {
            setGender("his")
          } else {
            setGender("her")
          }
        }
      })
    }
  })

  useEffect(() => {
    if (activeRower) {
      setShow(false)
    }
  }, [activeRower])

  const toggleBio = (): void => {
    setShow(!show)
  }

  const handleRowerChapter = useCallback(
    (time) => (): void => {
      navigate("/", { state: { time } })
    },
    []
  )

  return (
    <>
      <Text className={show ? "active" : ""}>
        {bio.map((item, i) => {
          return (
            <div key={i}>
              {i === 0 && (
                <div className="preview">
                  <p>
                    {item} <span onClick={toggleBio}>Continue Reading</span>
                  </p>
                  {chapter != 0 && (
                    <p
                      className="chapter-link"
                      onClick={handleRowerChapter(chapter)}
                    >
                      watch {gender} story of courage
                    </p>
                  )}
                </div>
              )}
              <p className="full-bio">
                {item} {i === 2 && <span onClick={toggleBio}>Close</span>}
              </p>
            </div>
          )
        })}
      </Text>
      <MobilePopup img={image} className={show ? "active" : ""}>
        <div className="overlay">
          <div className="content">
            {bio.map((text: string, i: any) => {
              return (
                <p key={i}>
                  {text} {i === 2 && <span onClick={toggleBio}>Close</span>}
                </p>
              )
            })}
            {chapter != 0 && (
              <p className="chapter-link" onClick={handleRowerChapter(chapter)}>
                watch {gender} story of courage
              </p>
            )}
          </div>
        </div>
      </MobilePopup>
    </>
  )
}

const MobilePopup = styled.div`
  width: 100%;
  height: 100vh;
  background-image: ${(props: { img: string }): string => `url(${props.img})`};
  background-size: cover;
  background-position: left;
  position: absolute;
  left: 0;
  top: -100vh;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  .overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5%;
    p {
      font-size: 0.7rem;
      font-family: "RMB-Sans";
      font-weight: 300;
      font-style: italic;
      padding-bottom: 15px;
      span {
        cursor: pointer;
        text-decoration: underline;
        transition: color 0.25s ease;
        font-weight: bold;
      }
    }
    .chapter-link {
      color: var(--gold);
      font-weight: bold;
      text-decoration: underline;
      margin-top: 50px;
      font-family: "RMB-Sans";
      font-weight: bold;
      font-style: italic;
    }
  }
  &.active {
    top: 0px;
    opacity: 1;
    z-index: 999;
  }
  @media (min-width: 768px) {
    display: none;
  }
`

const Text = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 380px;
  p,
  span {
    font-weight: 300;
    font-style: italic;
    color: var(--gray);
  }
  p {
    font-size: 0.6rem;
    padding-bottom: 10px;
    transition: opacity 0.25s ease;
    line-height: 20px;
    letter-spacing: 1px;
    span {
      cursor: pointer;
      position: relative;
      transition: color 0.25s ease;
      text-decoration: underline;
    }
  }
  .preview {
    margin: 30px 5% 0 5%;
  }
  .chapter-link {
    cursor: pointer;
    color: var(--gold);
    font-weight: bold;
    text-decoration: underline;
    margin-top: 50px;
    font-family: "RMB-Sans";
    font-weight: bold;
    font-style: italic;
  }
  .full-bio {
    opacity: 0;
    display: none;
  }
  &.active {
    .preview {
      opacity: 0;
    }
    .full-bio {
      opacity: 1;
    }
  }
  @media (min-width: 768px) {
    width: 55%;
    margin: 0 5% 0 auto;
    p {
      font-size: 0.9rem;
      span {
        &:hover {
          color: var(--gold);
        }
      }
    }
    .preview {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      margin: 0;
    }
    .chapter-link {
      display: block;
      transition: opacity 0.25s ease;
      &:hover {
        opacity: 0.6;
      }
    }
    .full-bio {
      display: block;
    }
  }
`

export default RowerInfo
