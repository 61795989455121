import React from "react"
import styled from "styled-components"
import backgroundImg from "../../images/player/ie.png"
import chromeIcon from "../../images/icons/chrome.svg"
import logo from "../../images/logo.png"

export interface Props {
  ie: boolean
  handleCloseIE: () => void
}

const InternetExplorer: React.FC<Props> = ({ ie, handleCloseIE }: Props) => {
  return (
    <Container className={ie ? "visible" : ""}>
      <Logo src={logo} alt="rmb-logo" />
      <Exit onClick={(): void => handleCloseIE()} />
      <DownloadChrome>
        <p>
          For a better interactive experience, please view in google chrome.
        </p>
        <a
          href="https://www.google.com/chrome/?brand=CHBD&gclid=Cj0KCQjwzZj2BRDVARIsABs3l9KYTDIBpP4SP27Y3_q92_NqXKRnLpmJ2vLrK7A7cwOij1xrFmdO5qcaAqE_EALw_wcB&gclsrc=aw.ds"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="flex">
            <img src={chromeIcon} />
            <p>Download Chrome</p>
          </div>
        </a>
      </DownloadChrome>
    </Container>
  )
}
const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  display: none;
  &.visible {
    display: block;
  }
`
const Logo = styled.img`
  width: 80px;
`
const Exit = styled.div`
  position: absolute;
  right: 25px;
  top: 35px;
  width: 35px;
  height: 35px;
  transition: opacity 0.25s ease;
  &::before {
    content: "";
    height: 2px;
    background: #fff;
    position: absolute;
    width: 100%;
    transform: rotate(-45deg);
  }
  &::after {
    content: "";
    height: 2px;
    background: #fff;
    position: absolute;
    width: 100%;
    transform: rotate(45deg);
  }
  @media (min-width: 1024px) {
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`

const DownloadChrome = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  p {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.8rem;
  }
  a {
    border: 1px solid #f2d65a;
    display: inline-block;
    text-align: center;
    margin: 25px auto 0 auto;
    padding: 10px 25px;
    transition: opacity 0.25s ease;
    .flex {
      display: flex;
      align-items: center;
    }
    p {
      color: #f2d65a;
      font-weight: 900;
    }
    img {
      width: 30px;
      margin-right: 10px;
    }
  }
  @media (min-width: 1024px) {
    a {
      &:hover {
        opacity: 0.6;
      }
    }
  }
`

export default InternetExplorer
