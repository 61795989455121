import React, { useEffect, useState } from "react"
import styled from "styled-components"

import DesktopRowers from "./DesktopRowers"
import MobileRowers from "./mobile"

const Container = styled.div`
  height: 100vh;
  overflow: scroll;
`

const Rowers: React.FC<{}> = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 900) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])
  const handleScreenSize = (): void => {
    if (window.innerWidth < 900) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleScreenSize)
  }, [isMobile])

  return (
    <Container>{isMobile ? <MobileRowers /> : <DesktopRowers />}</Container>
  )
}

export default Rowers
