import React, { useEffect, useState } from "react"
import styled from "styled-components"

import OrientationPopUp from "../elements/OrientationPopUp"
import InternetExplorer from "../elements/InternetExplorer"

const GlobalSVG = styled.svg`
  position: absolute;
  z-index: -1;
  pointer-events: none;
  .stop-1 {
    stop-color: rgb(93, 100, 101);
    animation: one 2.5s infinite alternate;
  }
  .stop-2 {
    stop-color: rgb(209, 212, 210);
    animation: two 2.5s infinite alternate;
  }
  .stop-3 {
    stop-color: rgb(242, 214, 90);
    animation: three 2.5s infinite alternate;
  }
  .stop-4 {
    stop-color: rgb(93, 100, 101);
    animation: four 2.5s infinite alternate;
  }
  @keyframes one {
    0% {
      stop-color: rgb(93, 100, 101);
    }
    100% {
      stop-color: rgb(209, 212, 210);
    }
  }
  @keyframes two {
    0% {
      stop-color: rgb(209, 212, 210);
    }
    100% {
      stop-color: rgb(93, 100, 101);
    }
  }
  @keyframes three {
    0% {
      stop-color: rgb(242, 214, 90);
    }
    100% {
      stop-color: rgb(93, 100, 101);
    }
  }
  @keyframes four {
    0% {
      stop-color: rgb(93, 100, 101);
    }
    100% {
      stop-color: rgb(242, 214, 90);
    }
  }
`

const GlobalSVGDegs = styled.defs``

interface Props {
  children?: any
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  // USED TO CHECK FOR IE
  const [ie, setIe] = useState(false)

  useEffect(() => {
    const isIE =
      !!window.navigator.userAgent.match(/Trident/g) ||
      !!window.navigator.userAgent.match(/MSIE/g)
    if (isIE) {
      setIe(true)
    }
  }, [])
  const handleCloseIE = (): void => {
    setIe(false)
  }
  return (
    <>
      <GlobalSVG>
        <GlobalSVGDegs>
          <linearGradient id="textGradient">
            <stop offset="15%" className="stop-1" />
            <stop offset="85%" className="stop-2" />
          </linearGradient>
          <linearGradient id="shareGradient">
            <stop offset="15%" className="stop-3" />
            <stop offset="85%" className="stop-4" />
          </linearGradient>
        </GlobalSVGDegs>
      </GlobalSVG>
      <InternetExplorer ie={ie} handleCloseIE={handleCloseIE} />
      <div>{children}</div>
      <OrientationPopUp />
    </>
  )
}

export default Layout
