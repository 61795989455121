const theme = {
  mediaQueries: {
    xs: "max-width: 768px",
    sm: "min-width: 768px",
    md: "min-width: 992px",
    lg: "min-width: 1200px",
  },
}

export default theme
