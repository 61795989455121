import { TimelineLite, gsap } from "gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import moment from "moment"
import React, { useCallback, useEffect, useRef } from "react"
import { isMobile } from "react-device-detect"
import styled from "styled-components"

gsap.registerPlugin(MotionPathPlugin)

const SeekerContainer = styled.div`
  position: absolute;
  bottom: 70px;
  width: 100%;
  @media (min-width: 768px) {
    bottom: 70px;
  }
  @media (min-width: 1200px) {
    bottom: 25px;
  }
`

const ShareInstructionsTop = styled.p`
  color: #ffffff;
  font-size: 11pt;
  font-style: italic;
  font-family: "RMB-Sans";
  max-width: 210px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  top: -50px;
  transition: opacity 300ms 400ms;
  opacity: ${(props) => (props.show ? 1 : 0)};
  @media (min-width: 768px) {
    font-size: 13pt;
    top: -30%;
  }
  @media (min-width: 1200px) {
    top: -10%;
  }
`

const ShareInstructionsBottom = styled.p`
  color: #ffffff;
  font-size: 10pt;
  font-family: "RMB-Sans";
  letter-spacing: 10px;
  padding-left: 5px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: -20px;
  margin-left: calc(50% + 10px);
  transform: translateX(-50%);
  transition: opacity 300ms 400ms;
  opacity: ${(props) => (props.show ? 1 : 0)};
  @media (min-width: 768px) {
    bottom: -20%;
    font-size: 16pt;
    letter-spacing: 20px;
    padding-left: 10px;
  }
  @media (min-width: 1200px) {
    bottom: 8%;
  }
`

const Indicator = styled.g`
  transform-box: fill-box;
  visibility: ${(props): any => (props.offset === "-1" ? "hidden" : "visible")};
  rect {
    pointer-events: all;
    stroke-width: 0px;
    stroke: rgba(0, 0, 0, 0.5);
    fill: white;
  }
`

const ShareButton = styled.g`
  cursor: pointer;
  visibility: ${(props): any =>
    props.showShare === false ? "hidden" : "visible"};
  circle {
    pointer-events: all;
    stroke: rgba(255, 255, 255, 0.4);
    stroke-width: 10;
    fill: none;
    fill: white;
  }

  .progress {
    stroke: rgba(255, 255, 255, 1);
    stroke-dasharray: 188;
    stroke-dashoffset: 188;
    stroke-width: 10;
  }
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`

const Time = styled.text`
  fill: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.08em;
  font-weight: 300;
  visibility: hidden;
  offset-path: path(
    "M-306.1,395.9c0,0,14.9-0.4,17.8-0.4s19.6-0.5,19.6-0.5s19.4-0.7,21.3-0.8s25.6-1.2,26.5-1.2s33.6-1.9,33.6-1.9
	l37.8-2.4l38.4-2.4l47.4-2l33.3-0.8c0,0,43.7,0.2,44.9,0.3s33.7,1.3,33.7,1.3l36.7,1.5l24.9,1.5l32.8,2.1L168,392l31.1,2.2l29.8,2.1
	l34.4,2.5l27.4,1.8L314,402l37.1,2.3l36.1,1.7l40.7,0.5c0,0,34.3-0.3,37-0.7s41.3-0.9,42.2-1s40.3-1.3,43.7-1.5s44.3-2.1,44.3-2.1
	l39.5-1.5l41.3-1l41.5-0.3l42.1,1.5l24,1l36.1,2.1l36.5,1.4l31.1,1.3l26.4,0.6l18.4,0.1"
  );
  offset-distance: 0%;
`

const SVG = styled.svg`
  .seeker {
    fill: url(#seekerGradient);
  }
  .seeker-path {
    fill: none;
    stroke: red;
    stroke-width: 0.5;
    stroke-miterlimit: 10;
  }
`

const ClickableArea = styled.rect`
  &:hover {
    + ${Time} {
      visibility: visible;
    }
  }
`

interface Props {
  progress: number
  onSeek?: (progress: number) => void
  onShare?: () => void
  totalDuration: number
  showShare?: boolean
  showShareInstructions?: boolean
  shareDuration?: number
}

const Seeker: React.FC<Props> = ({
  progress = 0,
  onSeek,
  onShare,
  totalDuration = 100,
  showShare = false,
  showShareInstructions = false,
  shareDuration = 0,
}: Props) => {
  const clickableAreaRef = useRef<SVGRectElement>(null)
  const timeRef = useRef<SVGTextElement>(null)
  const shareRef = useRef<SVGGElement>(null)
  const indicatorRef = useRef<SVGGElement>(null)
  const pathRef = useRef<SVGGElement>(null)

  const handleSeek = useCallback(
    (e) => {
      if (clickableAreaRef.current && onSeek) {
        const { left, width } = clickableAreaRef.current.getBoundingClientRect()
        const { clientX } = e
        const progress = ((clientX - left) / width) * 100
        onSeek(progress)
      }
    },
    [onSeek]
  )

  useEffect(() => {
    const containerElement = clickableAreaRef.current
    const handleMouseMove = (e: any): void => {
      if (timeRef.current) {
        const { x } = e
        const { width } = clickableAreaRef.current?.getBoundingClientRect() ?? {
          width: 0,
        }
        const percentage = x / width
        const duration = moment.duration(
          Math.round(totalDuration * percentage),
          "seconds"
        )
        timeRef.current.textContent = `${String(
          duration.get("minutes")
        ).padStart(2, "0")}’${String(duration.get("seconds")).padStart(
          2,
          "0"
        )}”`
        const style = timeRef.current.style as {
          offsetDistance: string
        } & CSSStyleDeclaration
        style.offsetDistance = `${percentage * 100}%`
      }
    }

    containerElement?.addEventListener("mousemove", handleMouseMove)

    return (): void => {
      containerElement?.removeEventListener("mousemove", handleMouseMove)
    }
  }, [totalDuration])

  useEffect(() => {
    // const indicatorTimeline: TimelineLite | null = null
    let shareTimeline: TimelineLite | null = null
    if (shareRef.current && indicatorRef.current) {
      if (showShare) {
        // indicatorTimeline = new TimelineLite()
        // indicatorTimeline
        //   .to(indicatorRef.current, {
        //     scale: 1.5,
        //     transformOrigin: "100% 100%",
        //     duration: 0.15,
        //   })
        //   .to(indicatorRef.current, {
        //     scale: 0.5,
        //     transformOrigin: "100% 100%",
        //     duration: 0.15,
        //   })
        //   .play()

        shareTimeline = new TimelineLite()

        shareTimeline.to(
          shareRef.current,
          {
            motionPath: {
              path: pathRef.current,
              align: pathRef.current,
              alignOrigin: [0.5, 0.5],
              start: 0.5,
              end: 0.235,
            },
            scale: isMobile ? 2.3 : 1,
            duration: 1,
            ease: "power4",
          },
          "+=0.15"
        )
        shareTimeline
          .to(
            shareRef.current.getElementsByTagName("circle"),
            {
              scale: 1.2,
              transformOrigin: "50% 50%",
              rotate: -90,
              duration: 0.15,
              ease: "power4",
            },
            "-=0.15"
          )
          .to(shareRef.current.getElementsByTagName("circle"), {
            scale: 1,
            transformOrigin: "50% 50%",
            rotate: -90,
            duration: 0.15,
            ease: "power4",
          })
        shareTimeline.to(
          shareRef.current.getElementsByTagName("image"),
          {
            scale: 1,
            transformOrigin: "50% 50%",
            duration: 0.15,
            ease: "power4",
          },
          "-=0.15"
        )
        shareTimeline.to(shareRef.current.querySelector(".progress"), {
          strokeDashoffset: 0,
          duration: shareDuration - 1.15,
          ease: "none",
        })
      } else {
        // indicatorTimeline = new TimelineLite()
        // indicatorTimeline
        //   .to(
        //     indicatorRef.current,
        //     {
        //       scale: 1.5,
        //       transformOrigin: "100% 100%",
        //       duration: 0.15,
        //     },
        //     "+=1"
        //   )
        //   .to(indicatorRef.current, {
        //     scale: 1,
        //     transformOrigin: "100% 100%",
        //     duration: 0.15,
        //   })
        //   .play()

        shareTimeline = new TimelineLite()
        shareTimeline
          .to(shareRef.current.getElementsByTagName("circle"), {
            scale: 1.2,
            transformOrigin: "50% 50%",
            rotate: -90,
            duration: 0.15,
            ease: "power4",
          })
          .to(shareRef.current.getElementsByTagName("circle"), {
            scale: 0.2,
            transformOrigin: "50% 50%",
            rotate: -90,
            duration: 0.15,
            ease: "power4",
          })
        shareTimeline.to(
          shareRef.current.getElementsByTagName("image"),
          {
            scale: 0,
            transformOrigin: "50% 50%",
            duration: 0.15,
            ease: "power4",
          },
          "-=0.15"
        )
        shareTimeline.to(
          shareRef.current,
          {
            motionPath: {
              path: pathRef.current,
              align: pathRef.current,
              alignOrigin: [0.5, 0.5],
              start: 0.23,
              end: 0.5,
            },
            scale: isMobile ? 2.3 : 1,
            duration: 1,
            ease: "linear",
          },
          "+=0.15"
        )

        shareTimeline.set(shareRef.current.querySelector(".progress"), {
          strokeDashoffset: 188,
        })
      }
    }

    return (): void => {
      // if (indicatorTimeline) {
      //   indicatorTimeline.kill()
      // }
      if (shareTimeline) {
        shareTimeline.kill()
      }
    }
  }, [showShare])

  useEffect(() => {
    let indicatorTimeline: TimelineLite | null = null
    if (indicatorRef.current) {
      indicatorTimeline = new TimelineLite()

      indicatorTimeline.to(indicatorRef.current, {
        motionPath: {
          path:
            "M-306.1,412.9c0,0,14.9-0.4,17.8-0.4s19.6-0.5,19.6-0.5s19.4-0.7,21.3-0.8s25.6-1.2,26.5-1.2s33.6-1.9,33.6-1.9 l37.8-2.4l38.4-2.4l47.4-2l33.3-0.8c0,0,43.7,0.2,44.9,0.3s33.7,1.3,33.7,1.3l36.7,1.5l24.9,1.5l32.8,2.1L168,409l31.1,2.2l29.8,2.1 l34.4,2.5l27.4,1.8L314,419l37.1,2.3l36.1,1.7l40.7,0.5c0,0,34.3-0.3,37-0.7s41.3-0.9,42.2-1s40.3-1.3,43.7-1.5s44.3-2.1,44.3-2.1 l39.5-1.5l41.3-1l41.5-0.3l42.1,1.5l24,1l36.1,2.1l36.5,1.4l31.1,1.3l26.4,0.6l18.4,0.1",
          alignOrigin: [0.5, 0.5],
          offsetY: -10,
          start: 0,
          end: progress / 100,
        },
        translateY: "-50%",
        duration: 0.01,
      })
    }
  }, [progress])

  return (
    <SeekerContainer>
      <ShareInstructionsTop show={showShare && showShareInstructions}>
        When this icon appears, share this story to show your support.
      </ShareInstructionsTop>
      <ShareInstructionsBottom show={showShare}>SHARE</ShareInstructionsBottom>
      <SVG
        version="1.1"
        id="Object"
        x="0px"
        y="0px"
        viewBox="-306.1 322.1 1238.2 179.7"
      >
        <g>
          <linearGradient
            id="seekerGradient"
            gradientUnits="userSpaceOnUse"
            x1="313"
            y1="1157.0541"
            x2="1551.2"
            y2="1157.0541"
            gradientTransform="matrix(1 0 0 1 0 -760)"
          >
            <stop offset="0" style={{ stopColor: "#DADADA" }} />
            <stop offset="0.9987" style={{ stopColor: "#B5B5B5" }} />
          </linearGradient>
          <path
            ref={pathRef}
            className="seeker"
            d="M-267.4,410.5c12.9-0.6,25.8-1.4,38.6-2.2l38.6-2.7l38.6-2.9l38.7-2.7c6.4-0.4,12.9-0.8,19.4-1.1l9.7-0.5
		l4.8-0.2l4.9-0.2c25.9-1.1,51.8-1.1,77.6-0.5c3.2,0,6.5,0.1,9.7,0.3l9.7,0.2c6.5,0.2,12.9,0.5,19.4,0.8l9.7,0.5l9.7,0.6
		c6.5,0.4,12.9,0.7,19.3,1.2c12.9,0.9,25.8,1.8,38.7,2.8l38.6,2.9c25.8,1.9,51.5,3.8,77.3,5.5l19.3,1.3l19.3,1.1
		c12.9,0.7,25.8,1.4,38.7,2c25.8,1.2,51.6,2,77.3,2.5c25.8,0.5,51.5,0.6,77.3,0s51.5-1.7,77.3-3l38.7-1.9
		c12.9-0.6,25.8-1.2,38.7-1.6c25.8-0.9,51.7-1.5,77.6-1.4s51.8,1.2,77.6,2.7c51.6,3,103,7.4,154.7,8.8v0.8
		c-12.9,0.3-25.8,0.4-38.8,0.2c-12.9-0.1-25.8-0.4-38.8-0.8c-12.9-0.4-25.8-0.9-38.7-1.4l-38.7-1.4c-12.9-0.4-25.8-0.8-38.7-1
		c-6.4-0.2-12.9-0.2-19.3-0.2s-12.9,0-19.3,0.1c-25.8,0.3-51.5,1-77.3,2.1l-77.4,3.3c-25.8,1-51.7,1.9-77.5,2.2
		c-25.9,0.3-51.8,0-77.6-1.1c-25.8-1-51.6-2.7-77.4-4.6c-12.9-0.9-25.8-1.9-38.6-3l-38.6-3c-25.7-2-51.5-4-77.3-5.7
		c-12.9-0.8-25.8-1.5-38.7-2.2c-12.9-0.6-25.8-1.2-38.7-1.6c-6.4-0.2-12.8-0.4-19.3-0.5l-9.7-0.3l-9.7-0.2
		c-12.9-0.3-25.8-0.4-38.7-0.5H-16c-6.4,0.1-12.9,0.1-19.3,0.1l-19.3,0.3l-19.3,0.6l-4.8,0.1l-4.8,0.2l-9.6,0.4
		c-6.4,0.3-12.9,0.6-19.3,1l-38.7,2.2c-51.5,2.9-103.3,5.5-155,4C-306.1,412.9-280.3,411.1-267.4,410.5z"
          />
        </g>
        <ClickableArea
          ref={clickableAreaRef}
          onClick={handleSeek}
          x="-306.1"
          y="360"
          width="100%"
          height="100"
          fill="rgba(255, 0, 0, 0)"
        />
        <Time ref={timeRef} fontSize={`12px`}>
          0:00
        </Time>
        <Indicator ref={indicatorRef}>
          <rect width="3" height="20" />
        </Indicator>

        <ShareButton ref={shareRef} showShare={showShare} onClick={onShare}>
          <circle cx="30px" cy="30px" r="30" className="progress" />
          <circle cx="30px" cy="30px" r="30" />
          <image
            className="share-image"
            href={require("../../images/player/indicator-bg.png")}
            x="0"
            y="0"
            height="60.5px"
            width="60.5px"
          />
          <image
            className="share-image"
            href={require("../../images/icons/share.svg")}
            x="5"
            y="3"
            height="50px"
            width="50px"
          />
        </ShareButton>
      </SVG>
    </SeekerContainer>
  )
}

export default Seeker
