import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { snakeCase } from "change-case"

interface Props {
  text: string
  fontSize?: number
  hoverSize?: number
  letterSpacing?: number
  hoverEffect?: boolean
  className?: string
}

const Text: React.ForwardRefRenderFunction<any, Props> = (
  {
    text,
    fontSize = 26,
    hoverSize = 60,
    letterSpacing = 12,
    hoverEffect = false,
    className = "",
    ...props
  }: Props,
  ref
) => {
  const textMaskId = `text-mask-${snakeCase(text)}`

  const [width, setWidth] = useState(fontSize * text.length)
  const [height, setHeight] = useState(fontSize)
  const viewBox = `0 0 ${width} ${height}`
  const textRef = useRef<SVGTextElement>(null)

  useEffect(() => {
    if (textRef.current) {
      try {
        const { width, height } = textRef.current.getBBox()
        setWidth(width)
        setHeight(height)
      } catch (err) {
        console.error("Firefox throws error on getBBox for mask elements")
      }
    }
  }, [text])

  return (
    <Container
      hoverSize={hoverSize}
      hoverEffect={hoverEffect}
      className={className}
    >
      <SVG
        ref={ref}
        width={width}
        height={height}
        viewBox={viewBox}
        letterSpacing={letterSpacing}
        {...props}
      >
        <defs>
          <mask
            id={textMaskId}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            height={height}
          >
            <text
              x="50%"
              y={height / 1.2}
              fontSize={`${fontSize}px`}
              fill="#999"
              textAnchor="middle"
            >
              {text}
            </text>
          </mask>
        </defs>
        <text
          style={{ visibility: "hidden" }}
          ref={textRef}
          fontSize={`${fontSize}px`}
          fill="#999"
          textAnchor="middle"
        >
          {text}
        </text>
        <g mask={`url(#${textMaskId})`}>
          <rect
            x="0"
            y="0"
            width={width}
            height={height}
            fill={
              className === "share-text"
                ? "url(#shareGradient)"
                : "url(#textGradient)"
            }
          />
          {hoverEffect && (
            <rect
              className="hover-fill"
              x="0"
              y="0"
              width={width}
              height={height}
              fill="#F2D65A"
            />
          )}
        </g>
      </SVG>
    </Container>
  )
}

export default React.forwardRef<any, Props>(Text)

const SVG = styled.svg<any>`
  transition: all ease 300ms;
  width: auto;
  max-width: 100%;
  text {
    text-transform: uppercase;
    font-family: "RMB-Sans Display";
    font-weight: 300;
    letter-spacing: ${(props: Props): any => props.letterSpacing}px;
  }
`

const Container = styled.div<any>`
  display: flex;
  align-items: center;
  margin: 5px 0;
  &.page {
    ${SVG} {
      .hover-fill {
        opacity: 1;
      }
      text {
        stroke-width: 0px;
        stroke: white;
      }
    }
  }
  &.share-text {
    svg {
      height: 20px;
    }
  }
  ${(props): any =>
    props.hoverEffect &&
    css`
      height: ${(props: Props): any => props && props.hoverSize}px;

      ${SVG} {
        .hover-fill {
          transition: all ease 600ms;
          opacity: 0;
        }
        text {
          stroke-width: 0px;
          stroke: white;
          transition: all ease 300ms;
        }
      }
      @media (min-width: 768px) {
        &:hover {
          ${SVG} {
            height: ${(props: Props): any => props && props.hoverSize}px;
            .hover-fill {
              opacity: 1;
            }
            text {
              font-family: "RMB-Sans Display";
              letter-spacing: 0.13em;
            }
          }
        }
      }
    `}
  @media(max-width: 768px) {
    &.share-text {
      svg {
        height: 15px;
      }
    }
    &.menu {
      svg {
        height: 18px;
      }
    }
    &.paused {
      svg {
        height: 14px;
      }
    }
  }
`
