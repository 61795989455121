import React from "react"
import styled from "styled-components"
import mouse from "../../images/icons/mouse.svg"

export interface Props {
  data: any
  activeRower: any
  select: any
}

const RowerList: React.FC<Props> = ({ data, activeRower, select }: Props) => {
  const renderNames = (): any => {
    return data.map((item: any, i: any) => {
      const className =
        activeRower === i
          ? "active"
          : activeRower === i + 1
          ? "prev"
          : activeRower === i - 1
          ? "next"
          : activeRower === i - 2
          ? "next-2nd"
          : activeRower === i + 2
          ? "prev-2nd"
          : activeRower === i - 3
          ? "next-3rd"
          : activeRower === i - 4
          ? "next-hidden"
          : activeRower === i + 3
          ? "prev-hidden"
          : ""
      return (
        <Name key={i}>
          <p className={className} onClick={(): any => select(i)}>
            {item.name} {item.surname}
          </p>
          <ActiveName className={className}>
            <h2 className="name">{item.name}</h2>
            <div className="flex">
              <h2 className="surname">{item.surname}</h2>
              <h3>
                <span>&#8212;</span> {item.description}
              </h3>
            </div>
          </ActiveName>
        </Name>
      )
    })
  }

  return (
    <Container>
      <div className="relative">
        {renderNames()}
        <ScrollIcon src={mouse} alt="scroll" />
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding-left: 3%;
  height: 100vh;
  width: 50%;
  .relative {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 50vh;
    width: 100%;
  }
`

const ScrollIcon = styled.img`
  position: absolute;
  bottom: -30px;
  left: 10%;
  width: 30px;
  height: auto;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  @media (min-width: 1024px) {
    left: 5%;
  }
`
const ActiveName = styled.div`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  transition: left 0.5s ease;
  &.active {
    opacity: 1;
    left: 25px;
  }
  h2 {
    text-transform: uppercase;
  }
  .flex {
    h3 {
      display: none;
      font-family: "RMB-Sans";
      font-weight: 300;
      font-style: italic;
      letter-spacing: 2px;
    }
  }
  .name {
    font-size: 2rem;
    font-family: "RMB-Sans-ExtraBold";
    margin-left: 20px;
  }
  .surname {
    font-size: 1rem;
    letter-spacing: 10px;
    font-weight: 300;
  }
  @media (min-width: 768px) {
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        display: initial;
        font-size: 12px;
        span {
          margin-right: 5px;
        }
      }
    }
    .name {
      font-size: 3.4rem;
      letter-spacing: 5px;
      margin-left: 20px;
    }
    .surname {
      font-size: 1.2rem;
      letter-spacing: 10px;
      margin-right: 30px;
    }
  }
`

const Name = styled.div`
  p {
    opacity: 0;
    color: white;
    position: absolute;
    font-size: 0.5rem;
    letter-spacing: 5px;
    text-transform: uppercase;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
    &:hover {
      cursor: pointer;
    }
    &.active {
      opacity: 0;
    }
    &.next {
      top: 75%;
      transform: translateY(-75%);
      opacity: 0.7;
    }
    &.next-2nd {
      top: 85%;
      transform: translateY(-85%);
      opacity: 0.4;
    }
    &.next-3rd {
      top: 95%;
      transform: translateY(-95%);
      opacity: 0.3;
    }
    &.next-hidden {
      top: 105%;
      transform: translateY(-105%);
      opacity: 0;
      pointer-events: none;
    }
    &.prev {
      top: 25%;
      transform: translateY(-25%);
    }
    &.prev-2nd {
      top: 15%;
      transform: translateY(-15%);
    }
    &.prev-hidden {
      top: 5%;
      transform: translateY(5%);
      pointer-events: none;
    }
  }
  @media (min-width: 768px) {
    p {
      font-size: 0.7rem;
      left: 10%;
      &.prev {
        opacity: 0.7;
      }
      &.prev-2nd {
        opacity: 0.4;
      }
      &.prev-hidden {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1024px) {
    p {
      left: 5%;
    }
  }
`

export default RowerList
