import { Link as LinkBase } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import { useLocation } from "@reach/router"
import Cookies from "universal-cookie"

import { GradientText, Header as HeaderBase } from "../elements"
import { Facebook, LinkedIn, Twitter } from "../elements/Icons"

const INVERSE_LOCATIONS = ["/episodes/"]
export interface Props {
  handleNavOpen: any
  isPlaying: any
  isIdle: any
  handleReset: any
  paused: any
}
const Header: React.FC<Props> = ({
  handleNavOpen,
  isPlaying,
  isIdle,
  handleReset,
  paused,
}: Props) => {
  const cookies = new Cookies()

  const [menuActive, setMenuActive] = useState(false)
  const [inverse, setInverse] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [cookie, setCookie] = useState(false)
  const [ie, setIe] = useState(false)

  const { pathname } = useLocation()

  const websiteUrl = process.env.WEBSITE_URL

  const shareURL = encodeURI(websiteUrl || "")
  const hashtags = "CourageCounts,Rowing,RMBNationalSquad"
  const shareMessage = encodeURI(
    "The South African rowing team are not defined by their odds, they are defined by their courage. Watch the full story here"
  )

  useEffect(() => {
    const isIE =
      !!window.navigator.userAgent.match(/Trident/g) ||
      !!window.navigator.userAgent.match(/MSIE/g)
    if (isIE) {
      setIe(true)
    }
  }, [])

  const handleToggleMenu = useCallback(() => {
    setMenuActive(!menuActive)
    if (handleNavOpen) {
      handleNavOpen(!menuActive)
    }
  }, [menuActive])

  useEffect(() => {
    if (paused) {
      setIsPaused(true)
    } else setIsPaused(false)
  }, [paused])

  useEffect(() => {
    if (isPlaying && isIdle) {
      setIsHidden(true)
    } else {
      setIsHidden(false)
    }
  }, [isPlaying, isIdle])

  useEffect(() => {
    setMenuActive(false)
    if (INVERSE_LOCATIONS.includes(pathname)) {
      setInverse(true)
    } else {
      setInverse(false)
    }
  }, [pathname])

  useEffect(() => {
    setCookie(cookies.get("setCookiePolicy"))
  }, [])

  const handleCookie = useCallback(
    (e) => {
      e.preventDefault()
      setCookie(true)
      cookies.set("setCookiePolicy", true, { path: "/" })
    },
    [cookie]
  )

  const renderPageList = (): any => {
    if (ie) {
      return (
        <Menu>
          <Link to="/" onClick={handleToggleMenu}>
            <p className={pathname === "/" ? "ie-text page" : "ie-text"}>
              Documentary
            </p>
          </Link>
          <Link to="/episodes/" onClick={handleToggleMenu}>
            <p
              className={pathname === "/episodes/" ? "ie-text page" : "ie-text"}
            >
              Episodes
            </p>
          </Link>
          <Link to="/rowers/" onClick={handleToggleMenu}>
            <p className={pathname === "/rowers/" ? "ie-text page" : "ie-text"}>
              Rowing Heroes
            </p>
          </Link>
          <ExternalLink
            href="https://www.rmb.co.za/rmb-and-rowing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="ie-text">RMB &amp; Rowing</p>
          </ExternalLink>
        </Menu>
      )
    } else {
      return (
        <Menu>
          <Link to="/" onClick={handleToggleMenu}>
            <GradientText
              hoverEffect
              text="Documentary"
              className={pathname === "/" ? "menu page" : "menu"}
            />
          </Link>
          <Link to="/episodes/" onClick={handleToggleMenu}>
            <GradientText
              hoverEffect
              text="Episodes"
              className={pathname === "/episodes/" ? "menu page" : "menu"}
            />
          </Link>
          <Link to="/rowers/" onClick={handleToggleMenu}>
            <GradientText
              hoverEffect
              text="Rowing Heroes"
              className={pathname === "/rowers/" ? "menu page" : "menu"}
            />
          </Link>
          <ExternalLink
            href="https://www.rmb.co.za/rmb-and-rowing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GradientText hoverEffect text="RMB & Rowing" className="menu" />
          </ExternalLink>
        </Menu>
      )
    }
  }
  return (
    <>
      <HeaderContainer
        inverse={inverse}
        onToggleClick={handleToggleMenu}
        toggleActive={menuActive}
        isHidden={isHidden}
        isPaused={isPaused}
        onHandleReset={handleReset}
        className={menuActive ? "active" : ""}
      />
      <MenuContainer className={menuActive ? "active" : ""}>
        <Padding></Padding>
        {renderPageList()}
        <div className="center">
          <SocialMediaLinks>
            <a
              href={`https://www.facebook.com/dialog/share?app_id=1118653035177314&display=popup&href=${shareURL}&quote=${shareMessage}&hashtag=%23RMBNationalSquad`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook width={40} />
            </a>
            <a
              href={`https://twitter.com/intent/tweet?text=${shareMessage}&url=${shareURL}&hashtags=${hashtags}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter width={40} />
            </a>
            <a
              href={`https://www.linkedin.com/cws/share/?url=${shareURL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedIn width={40} />
            </a>
          </SocialMediaLinks>
          <ShareText>
            {ie ? (
              <p>SHARE</p>
            ) : (
              <GradientText text="SHARE" className="share-text" />
            )}
          </ShareText>
        </div>
        <Cookie className={cookie ? "hide" : ""}>
          <div className="flex">
            <p>
              We use cookies to provide you the best possible online experience.{" "}
              <a
                href="https://www.rmb.co.za/page/firstrand-cookie-notice"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read our cookie policy.
              </a>
            </p>
            <div
              className="close"
              onClick={(e): void => {
                handleCookie(e)
              }}
            />
          </div>
        </Cookie>
      </MenuContainer>
    </>
  )
}

export default Header

const ShareText = styled.div`
  p {
    font-size: 1.2rem;
    color: #f2d65a;
    letter-spacing: 2px;
  }
`

const Cookie = styled.div`
  &.hide {
    opacity: 0;
    .flex {
      .close {
        &:after {
          height: 0;
        }
        &:before {
          height: 0;
        }
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
    p {
      padding-right: 5px;
      text-align: center;
      font-size: 0.6rem;
      letter-spacing: 1px;
      color: var(--grey);
      line-height: 15px;
      a {
        font-weight: 800;
      }
    }
    .close {
      height: 25px;
      width: 25px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 25px;
        background: var(--grey);
        transform: rotate(45deg);
        top: 10px;
      }
      &:before {
        content: "";
        position: absolute;
        height: 1px;
        width: 25px;
        background: var(--grey);
        transform: rotate(-45deg);
        top: 10px;
      }
    }
  }
  @media (min-width: 768px) {
    .flex {
      a {
        transition: opacity 0.25s ease;
        &:hover {
          opacity: 0.5;
        }
      }
      .close {
        transition: opacity 0.25s ease;
        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
`

const HeaderContainer = styled(HeaderBase)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &.active {
    position: fixed;
  }
`

const MenuContainer = styled.div`
  position: fixed;
  overflow-y: scroll;
  padding: var(--gutter-s);
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: black;
  pointer-events: none;
  transition: opacity ease 300ms;
  width: 100vw;
  height: 100%;
  opacity: 0;
  z-index: 100;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ie-text {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 20px 10px;
    font-weight: 300;
    transition: all 0.25s ease;
    &.page {
      color: #f2d65a;
    }
  }
  .mobile-link {
    padding: 20px 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    width: 100%;
    font-weight: 300;
  }
  @media (min-width: 768px) {
    .mobile-link {
      font-size: 2.4rem;
    }
    .ie-text {
      font-size: 2.4rem;
      &:hover {
        opacity: 0.6;
        color: #f2d65a;
      }
    }
  }
`

const Link = styled(LinkBase)``

const ExternalLink = styled.a``

const SocialMediaLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: block;
    width: 40px;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const Padding = styled.div`
  height: 50px;
`
