import React from "react"
import styled from "styled-components"

import instagram from "../../images/icons/instagram-gold.svg"
import facebook from "../../images/icons/facebook-gold.svg"
import twitter from "../../images/icons/twitter-gold.svg"

export interface Props {
  activeRower: any
  data: any
}

const Social: React.FC<Props> = ({ data, activeRower }: Props) => {
  return data.map((item: any, i: any) => {
    if (activeRower === i) {
      return (
        <Container key={i}>
          <Flex>
            <p>follow {item.name}</p>
            {item.facebook != "" && (
              <SocialLink
                href={item.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </SocialLink>
            )}
            {item.twitter != "" && (
              <SocialLink
                href={item.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="twitter" />
              </SocialLink>
            )}
            {item.instagram != "" && (
              <SocialLink
                href={item.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="instagram" />
              </SocialLink>
            )}
          </Flex>
        </Container>
      )
    } else {
      return null
    }
  })
}

const Container = styled.div`
  position: absolute;
  bottom: 5%;
  left: 5%;
  transform: translate(-5%, -5%);
  @media (min-width: 1024px) {
    left: 58%;
    bottom: 5%;
    transform: translate(-65%, -10%);
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 0.8rem;
    font-weight: bold;
    margin-right: 10px;
    color: var(--gold);
    display: none;
    font-style: italic;
    text-transform: lowercase;
  }
  @media (min-width: 1024px) {
    p {
      display: initial;
    }
  }
`

const SocialLink = styled.a`
  width: 35px;
  height: 35px;
  padding: 2px;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.5;
  }
  @media (min-width: 1024px) {
    width: 25px;
    height: 25px;
  }
`
export default Social
