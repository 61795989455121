import { navigate } from "gatsby"
import React, { useMemo, useRef } from "react"
import styled from "styled-components"

import { useLocation } from "@reach/router"

interface Props {
  inverse?: boolean
  onToggleClick: () => void
  onHandleReset?: () => void
  toggleActive?: boolean
  isHidden?: boolean
  isPaused?: boolean
}

const Header: React.FC<Props> = ({
  inverse = false,
  onToggleClick,
  onHandleReset,
  toggleActive = false,
  isHidden = false,
  isPaused = false,
  ...props
}: Props) => {
  const { pathname } = useLocation()
  const activeClass = useRef("")
  useMemo(() => {
    if (pathname === "/episodes/" && !toggleActive)
      activeClass.current = "white"
    else if (pathname === "/episodes/" && toggleActive)
      activeClass.current = "black"
  }, [toggleActive])

  const handleLogoClick = (): void => {
    if (pathname === "/" && onHandleReset) {
      onHandleReset()
    } else {
      navigate("/")
    }
  }

  return (
    <HeaderContainer {...props} className={activeClass.current}>
      {inverse && !toggleActive ? (
        <LogoContainer>
          <Logo
            onClick={handleLogoClick}
            src={require("../../images/logo-black-new.png")}
            alt="RMB Logo"
          />
          <Logo
            src={require("../../images/endorsement-logo-black.png")}
            alt="RMB Endorsement Logo"
            className="endorsement"
          />
        </LogoContainer>
      ) : (
        <LogoContainer>
          <Logo
            onClick={handleLogoClick}
            src={require("../../images/logo-new.png")}
            alt="RMB Logo"
            className={!toggleActive && isPaused ? "hide-logo" : ""}
          />
          <Logo
            src={require("../../images/endorsement-logo.png")}
            alt="RMB Endorsement Logo"
            className={!toggleActive && isPaused ? "hide-logo" : "endorsement"}
          />
        </LogoContainer>
      )}
      <HideContainer className={isHidden ? "" : ""}>
        <ToggleContainer
          className={toggleActive ? "active menu" : "menu"}
          inverse={inverse}
          onClick={onToggleClick}
        />
      </HideContainer>
    </HeaderContainer>
  )
}

export default Header

const HideContainer = styled.div`
  &.hide {
    opacity: 0;
  }
`
const HeaderContainer = styled.div`
  display: flex;
  z-index: 101;
  width: 100%;
  position: fixed !important;
  justify-content: space-between;
  align-items: center;
  padding: var(--gutter-s) 15px;
  &.black {
    background-color: black;
  }
  &.white {
    background-color: var(--white);
  }
  @media (min-width: 768px) {
    padding: var(--gutter-s) 30px;
  }
`

const LogoContainer = styled.div`
  display: flex;
`

const ToggleContainer = styled.div<{ inverse: boolean }>`
  position: relative;
  height: 36px;
  width: 30px;

  &:hover {
    cursor: pointer;
  }

  &:before,
  &:after {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: ${(props: any): any =>
      props.inverse ? "black" : "white"};
    left: 0;
    display: block;
    content: "";
    transition: all ease 300ms;
    transform-origin: top center;
  }

  &:before {
    top: 11px;
  }
  &:after {
    top: none;
    bottom: 11px;
  }

  &.active {
    &:before,
    &:after {
      background-color: white;
    }
    &:before {
      top: 50%;
      transform: rotate(-45deg) translate(0%, -50%);
    }
    &:after {
      top: 50%;
      transform: rotate(45deg) translate(0%, -50%);
    }
  }
`

const Logo = styled.img`
  width: 90px;
  margin: auto;
  &.hide-logo {
    opacity: 0;
  }
  @media (min-width: 768px) {
    cursor: pointer;
    &.endorsement {
      cursor: unset;
    }
  }
  @media (min-width: 1024px) {
    width: 120px;
  }
`
