import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

import { GradientText, Header as HeaderBase } from "../elements"
import { Facebook, LinkedIn, Twitter } from "../elements/Icons"

interface Props {
  active: boolean
  onDismiss: () => void
  episode: any
}

const ShareModal: React.FC<Props> = ({
  active = false,
  onDismiss,
  episode = {},
}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "player/share-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2017, maxHeight: 1279) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const websiteUrl = process.env.WEBSITE_URL
  let url = websiteUrl
  if (episode?.number) {
    url = websiteUrl + `/episodes/${episode.number}`
  }

  const shareURL = encodeURI(url || "")

  const hashtags = (episode?.hashtags || []).join(",")
  const message =
    episode?.shareMessage ||
    "The South African rowing team are not defined by their odds, they are defined by their courage. Watch the full story here: "
  const twitterMessage = episode?.twitterShareMessage || message

  const shareMessage = encodeURI(message)

  return (
    <Container className={active ? "active" : ""}>
      <Header onToggleClick={onDismiss} toggleActive={active} />
      <Background
        fluid={data.bg.childImageSharp.fluid}
        alt={`Loading image splash`}
      />
      <Content>
        <GradientText text="Share to" />
        <SocialMediaLinks>
          <a
            href={`https://www.facebook.com/dialog/share?app_id=1118653035177314&display=popup&href=${shareURL}&quote=${shareMessage}&hashtag=%23RMBNationalSquad`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook width={40} />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${twitterMessage}&url=${shareURL}&hashtags=${hashtags}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter width={40} />
          </a>
          <a
            href={`https://www.linkedin.com/cws/share/?url=${shareURL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn width={40} />
          </a>
        </SocialMediaLinks>
      </Content>
    </Container>
  )
}

export default ShareModal

const Container = styled.div`
  position: absolute;
  z-index: 105;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all ease 600ms;

  &.active {
    transition: all ease 300ms;
    opacity: 1;
    pointer-events: all;
  }
`

const Background = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SocialMediaLinks = styled.div`
  display: flex;
  align-items: center;
  a {
    display: block;
    margin: 7vh 20px 0 20px;
    width: 40px;
  }
`

const Header = styled(HeaderBase)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050;
`
