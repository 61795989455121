import { navigate } from "gatsby"
import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { EffectFade, Swiper } from "swiper/js/swiper.esm.js"

import icon from "../../../images/icons/arrow-down.svg"
import facebook from "../../../images/icons/facebook-gold.svg"
import instagram from "../../../images/icons/instagram-gold.svg"
import twitter from "../../../images/icons/twitter-gold.svg"
import { ROWER_DATA } from "../data"

const MobileRowers: React.FC<{}> = () => {
  Swiper.use([EffectFade])

  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)

  const [isBioExpanded, toggleBio] = useState(false)

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 500,
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      effect: "fade",
      initialSlide: activeSlide,
      fadeEffect: {
        crossFade: true,
      },
      on: {
        slideChange: (): void => {
          if (swiper.current) {
            setActiveSlide(swiper.current.activeIndex - 1)
          }
        },
        slideChangeTransitionEnd: (): void => {
          if (swiper.current) {
            if (swiper.current.activeIndex === 8) {
              swiper.current.slideTo(1)
            }
          }
        },
      },
    })
  }, [])

  const renderPagination = (): any => {
    return ROWER_DATA.map((rower, i) => {
      return <span key={i} className={i === activeSlide ? "active" : ""} />
    })
  }

  const handleRowerChapter = useCallback(
    (time) => (): void => {
      navigate("/", { state: { time } })
    },
    []
  )

  const renderSlides = (): any => {
    return ROWER_DATA.map((rower: any, i: any) => {
      return (
        <Slide className="swiper-slide" key={i}>
          <Photo>
            <img src={rower.profileImg} alt="RMB" />
          </Photo>
          <Name>
            <h1>{rower.name}</h1>
            <h2>{rower.surname}</h2>
          </Name>
          <Pagination>{renderPagination()}</Pagination>
          <Bio className={isBioExpanded ? "expanded" : ""}>
            <div className="bio">
              {rower.rowerBio.map((text: string, i: any) => {
                return <p key={i}>{text}</p>
              })}
            </div>
            <div
              className="panel"
              onClick={(): void => toggleBio(!isBioExpanded)}
            >
              <img src={icon} alt="RMB" />
              {isBioExpanded ? (
                <span>Close</span>
              ) : (
                <span>Continue reading</span>
              )}
            </div>
          </Bio>
          <StoryLink>
            {rower.videoChapter != 0 && (
              <span onClick={handleRowerChapter(rower.videoChapter)}>
                {`watch ${rower.genderPronoun} story of courage`}
              </span>
            )}
          </StoryLink>
          <SocialPanel>
            <div className="links">
              {rower.facebook != "" && (
                <a
                  href={rower.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="facebook" />
                </a>
              )}
              {rower.twitter != "" && (
                <a
                  href={rower.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="twitter" />
                </a>
              )}
              {rower.instagram != "" && (
                <a
                  href={rower.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="instagram" />
                </a>
              )}
            </div>
          </SocialPanel>
        </Slide>
      )
    })
  }

  return (
    <Container>
      <SwiperContainer ref={swiperElement} className="swiper-container">
        <div className="swiper-wrapper">{renderSlides()}</div>
      </SwiperContainer>
    </Container>
  )
}

// STYLES
const Container = styled.div`
  background: black;
`

const SwiperContainer = styled.div`
  height: 100%;
  width: 100%;
  .swiper-wrapper {
  }
`

const Slide = styled.div`
  /* background-color: rgba(255, 0, 0, 0.25); */
  color: white;
  display: grid;
  min-height: 100vh;
  grid-template: 60vh auto auto 1fr 1fr / 1fr;
`

const Photo = styled.div`
  /* background-color: rgba(255, 255, 255, 0.5); */
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;
  }
`

const Name = styled.div`
  /* background-color: rgba(255, 0, 255, 0.25); */
  position: relative;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  h1,
  h2 {
    text-transform: uppercase;
  }
  h1 {
    margin-left: 10px;
    font-size: 1.4rem;
    font-family: "RMB-Sans-ExtraBold";
  }
  h2 {
    font-weight: 300;
    font-size: 0.8rem;
    letter-spacing: 10px;
  }
`

const Pagination = styled.div`
  /* background-color: rgba(0, 255, 255, 0.25); */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 38vw 35px;
  span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    transform: scale(1);
    transition: transform 500ms ease-in-out;
    background-color: rgba(255, 255, 255, 0.5);
    &.active {
      transform: scale(2);
      background-color: rgba(255, 255, 255, 1);
    }
  }
`

const Bio = styled.div`
  /* background-color: rgba(0, 0, 255, 0.25); */
  position: relative;
  padding: 0 15px;
  //
  .bio {
    transition: all 500ms ease-in-out;
    max-height: 80px;
    overflow: hidden;
    p {
      font-size: 0.8rem;
      font-weight: 300;
      font-style: italic;
      color: var(--gray);
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
  .panel {
    display: flex;
    align-items: center;
    padding: 10px 0;
    img {
      width: 30px;
      height: 30px;
      margin-right: 1rem;
      object-fit: contain;
    }
    span {
      font-size: 0.8rem;
      font-weight: 700;
      font-style: italic;
      color: var(--gray);
      line-height: 20px;
      text-decoration: underline;
      letter-spacing: 1px;
    }
  }
  &.expanded {
    .bio {
      max-height: 1000px;
    }
    .panel {
      img {
        transform: scale(-1, -1);
      }
    }
  }
`

const StoryLink = styled.div`
  /* background-color: rgba(0, 255, 0, 0.25); */
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  span {
    font-size: 0.8rem;
    color: var(--gold);
    font-weight: bold;
    text-decoration: underline;
    font-family: "RMB-Sans";
    font-weight: bold;
    font-style: italic;
  }
`

const SocialPanel = styled.div`
  /* background-color: rgba(255, 255, 0, 0.25); */
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 15px 0 5px;
  .links {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
  .arrow {
    display: flex;
    align-items: center;
    img {
      width: 25px;
      height: 25px;
      object-fit: contain;
      transform: rotate(-90deg);
    }
  }
`

export default MobileRowers
