import { useEffect, useState } from "react"

export const useIdleTimer = () => {
  const [isIdle, setIsIdle] = useState(false)

  useEffect(() => {
    let timeoutId: number | null = null

    const clearTimeout = (): void => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }

    const initTimeout = (): void => {
      timeoutId = window.setTimeout(() => {
        setIsIdle(true)
      }, 2000)
    }

    const handleActivity = (): void => {
      setIsIdle(false)
      clearTimeout()
      initTimeout()
    }

    initTimeout()

    window.addEventListener("mousemove", handleActivity)
    window.addEventListener("click", handleActivity)
    window.addEventListener("keydown", handleActivity)

    return (): void => {
      clearTimeout()
      window.removeEventListener("mousemove", handleActivity)
      window.removeEventListener("click", handleActivity)
      window.removeEventListener("keydown", handleActivity)
    }
  }, [])

  return isIdle
}
