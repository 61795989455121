// LARGE IMAGES
import lawrence from "../../images/rowers/lawrence.jpg"
import kirsten from "../../images/rowers/kirsten.jpg"
import roger from "../../images/rowers/roger.jpg"
import sandra from "../../images/rowers/sandra.jpg"
import sizwe from "../../images/rowers/sizwe.jpg"
import thabelo from "../../images/rowers/thabelo.jpg"
import virginia from "../../images/rowers/virginia.jpg"
// PROFILE IMAGES
import lawrenceProfile from "../../images/rowers/profile/lawrence.jpg"
import kirstenProfile from "../../images/rowers/profile/kirsten.jpg"
import rogerProfile from "../../images/rowers/profile/roger.jpg"
import sandraProfile from "../../images/rowers/profile/sandra.jpg"
import sizweProfile from "../../images/rowers/profile/sizwe.jpg"
import thabeloProfile from "../../images/rowers/profile/thabelo.jpg"
import virginiaProfile from "../../images/rowers/profile/virginia.jpg"

export const ROWER_DATA = [
  {
    name: "Lawrence",
    surname: "Brittain",
    genderPronoun: "his",
    description: "heavyweight pair",
    image: lawrence,
    profileImg: lawrenceProfile,
    instagram: "https://www.instagram.com/brittain_l/",
    facebook: "https://www.facebook.com/lawrence.brittain.7",
    twitter: "https://twitter.com/Brittain_L",
    videoChapter: 512,
    rowerBio: [
      "Courage is in Lawrence’s DNA. After being diagnosed with stage 4 cancer while training for the 2016 Olympics, and having to take five months off to do chemo, Lawrence not only beat Hodgkin’s Lymphoma – without skipping a beat (or a qualifier) – he went on to win a silver medal at that very same Olympics.",
      "Rowers will tell you that training is everything. If you miss one day, it sets you back the equivalent of one week. In Lawrence’s case, even though he wasn’t feeling well or couldn’t understand why his performance was sliding, he never stopped training, right up until he was diagnosed (the difference from training to chemo was just one week). Missing five months of training because he was fighting for his life, meant that Lawrence had to work unimaginably hard to get back on track to being race fit, let alone Olympics fit.",
      "But he did it! And he continues to show courage with his ‘never say die’ attitude. Last year he broke his ankle, but still qualified the boat for the Tokyo Olympics, moonboot and all. If stage 4 cancer and broken bones will not make Lawrence give up, then nothing will. Lawrence only has one standard – the gold standard. To us, his gold standard is his courage. ",
    ],
  },
  {
    name: "Kirsten",
    surname: "McCann",
    genderPronoun: "her",
    description: "lightweight double",
    image: kirsten,
    profileImg: kirstenProfile,
    instagram: "https://www.instagram.com/kirstrow/",
    facebook: "",
    twitter: "",
    videoChapter: 226,
    rowerBio: [
      "Courage is Kirsten McCann’s middle name. She is no stranger to getting things done. From balancing a full-time professional rowing career with a full-time postgraduate degree in biochemistry, which is not for the fainthearted, one could say that time management is her strength. But strength takes on many forms for the World Rowing Championships 2017 gold medalist (a historic first for SA women).",
      "To train and perform at the level of an elite athlete, recovery is just as important as the training itself. The expression goes: ‘If you’re standing, you should be sitting, and if you’re sitting, you should be lying down.’ Kirsten does not get to recover much amid studying, attending lectures, attending lab practicals and writing exams. It’s mind-blowing that she performs as well as she does, in both areas. ",
      "Kirsten is lightweight in stature, but has a heavyweight winning mentality – she is considered one of the toughest members on the SA squad. She pushes her body harder than anyone else, but it’s her mental strength that she’s particularly respected for. She leads by example, showing the younger members on the squad how it’s done. She is a gutsy racer and has only one thing on her mind: Olympic gold. She is all in, and we are behind her.",
    ],
  },
  {
    name: "Roger",
    surname: "Barrow",
    genderPronoun: "his",
    description: "head coach, rowing sa",
    image: roger,
    profileImg: rogerProfile,
    instagram: "https://www.instagram.com/barrowrow/",
    facebook: "https://www.facebook.com/roger.barrow.16",
    twitter: "https://twitter.com/rogerbarrow",
    videoChapter: 445,
    rowerBio: [
      "Roger hates the word ‘sacrifice’ because he firmly believes: ‘You get out what you put in’. His ‘no holds barred’ attitude, combined with personal rowing experience, science, intricate data and constant out-the-box efforts to challenge the rowers physically, mentally and even emotionally, is what makes Roger a formidable coach and the SA squad’s best. And it’s not just our rowers who think so, Roger was voted Global Coach of the Year in 2016 – a huge feat considering his young age. ",
      "His job as head coach is not an easy one. He spends considerable time away from his family and is constantly juggling very tight resources and budgets (rowing is not well-funded by government), which often leaves him backed up against a wall, having to make ruthless decisions. But you won’t catch him complaining, or letting on anything to his rowers. His job is to ensure that they only worry about one thing: winning. ",
      "At times, Roger has always done whatever it takes to ensure that his squad have what they need to succeed. Such is his passion and belief in SA rowing, largely considered an underdog sport compared to overseas rivals who have 10 times the resources, that he turned down a top coaching position in Australia, a country with a massive national sports budget. Amid the brain drain scourge, this is something that resonates with South Africans, but particularly with his team. He doesn’t think this is courageous, but we certainly do. ",
    ],
  },
  {
    name: "Sandra",
    surname: "Khumalo",
    genderPronoun: "her",
    description: "paralympic rower",
    image: sandra,
    profileImg: sandraProfile,
    instagram: "https://www.instagram.com/sandra_khumalo/",
    facebook: "https://www.facebook.com/sandra.siba",
    twitter: "https://twitter.com/khumalo_sandra",
    videoChapter: 0,
    rowerBio: [
      "If you look up ‘courage’ in the dictionary, it would be only fitting to find a write up about Sandra Khumalo, as she is the very definition of what it means to be courageous. After surviving a horrific car accident that left her paralysed from the waist down, Sandra didn’t just defy the odds, she obliterated them by taking up rowing. ",
      "This is a huge feat in itself – imagine being unable to swim, but putting yourself on a boat, alone, in deep water. Not only that, rowing is a full-body sport. Sandra propels the boat solely with her arms and shoulders – a huge show of strength, both physically and mentally. She did not just show the world that she could row, she showed the world that she was good. So good, in fact, that she competed in the 2012 and 2016 Paralympics and at the time, was ranked fifth in the world. She also went on to win gold at the 2019 Tunisia International Sprint Regatta.",
      "Rowing gave Sandra a new life – a thrilling feeling of independence and control. Sandra proves that nothing is impossible and currently has her sights set on winning an Olympic medal. We are behind her every stroke of the way.",
    ],
  },
  {
    name: "Sizwe",
    surname: "Ndlovu",
    genderPronoun: "his",
    description: "veteran olympic gold medalist",
    image: sizwe,
    profileImg: sizweProfile,
    instagram: "https://www.instagram.com/sizwelndlovu/",
    facebook: "https://www.facebook.com/lawrence.ndlovu.754",
    twitter: "https://twitter.com/SizweLNdlovu",
    videoChapter: 334,
    rowerBio: [
      "As far as sporting heroes go, Sizwe Ndlovu is a household name, thanks largely to being part of the lightweight men’s four that won Olympic gold in 2012. This was a first for SA, but phenomenally, Sizwe was the first black rower in the world ever to do so. The ‘magic crew’ as they were called made history, and Sizwe’s life was transformed.",
      "But he didn’t always have it easy. Like many South Africans, Sizwe grew up in a township and went to bed hungry, many nights. His struggle with poverty was long and arduous. Things slowly started to change when Sizwe’s school headmaster saw potential in him and took him under his wing, persuading him to start rowing. This belief in Sizwe, combined with his newfound self-belief and natural talent, saw him persevere, no matter how difficult the odds. ",
      "Sizwe not only rose above poverty, but soared above injury, illness, loss and almost retiring from rowing after not making the cut for the 2004 and 2008 Olympics. It took courage to give it one last shot – the historic 2012 Olympics. This race made the country take notice of rowing, and helped put it on the map as a sport to be invested in. Sizwe, in particular, has been a source of inspiration for children living in similar circumstances to those he endured. From a township in KZN to standing on the Olympic podium, Sizwe embodies the true spirit of South African courage. He is a symbol of hope for generations to come. ",
    ],
  },
  {
    name: "Thabelo",
    surname: "Masutha",
    genderPronoun: "his",
    description: "single scull",
    image: thabelo,
    profileImg: thabeloProfile,
    instagram: "https://www.instagram.com/thabsybaby/",
    facebook: "https://www.facebook.com/thabelo.masutha.1",
    twitter: "",
    videoChapter: 118,
    rowerBio: [
      "For 20-year old Thabelo, it’s not about the races you’ve won, but about the ones you’ve lost – demonstrating an enormous amount of maturity for the youngest member in the under-23 squad. He is enormous too at 1.97m tall. Thabelo prefers the single scull – a solitary boat where there is no one to guide or motivate you, except yourself. Again, a testament to his maturity and quiet confidence.",
      "But it hasn’t always been smooth sailing for him. Thabelo was diagnosed with a heart irregularity a few years ago where doctors found thick tissue on the valve. Amazingly this diagnosis did not hold him back, and he went on to win a bronze medal at the World Junior Rowing Championships in 2017, becoming the first black rower ever in the world to do so. A feat even more outstanding when you consider the sheer amount of intense cardio Thabelo does every day, all the more with this heart condition. It takes heart-thumping courage.",
      "Thabelo has a bright future ahead of him, but he prefers to take it one day at a time. It’s this humble attitude, training ethic and sheer talent that will see Thabelo through to the finish line, and we can’t wait for the day that finish line is the greatest finish line on earth.",
    ],
  },
  {
    name: "Virginia",
    surname: "Mabaso",
    genderPronoun: "her",
    description: "development coordinator for rowing sa",
    image: virginia,
    profileImg: virginiaProfile,
    instagram: "https://www.instagram.com/funimdesigns/",
    facebook: "https://www.facebook.com/virginia.mabaso",
    twitter: "https://twitter.com/FuniMdesigns",
    videoChapter: 0,
    rowerBio: [
      "Hailing from a small village in rural Limpopo, Virginia had never seen rowing before, let alone imagined that one day she would be the Development Coordinator for Rowing SA. Virginia is not just passionate about the sport, she is the driving force of rowing transformation in SA. She literally drives over 140 000km in her Chery Tiggo to remote places all over the country to find and recruit young rowing talent in her ‘Learn to Row Rural Programme’. ",
      "To date, she has helped train 3 210 rowers and even coaches, achieving her five-year goal in three years. It’s this tenacity, perseverance and passion that saw her win the SA Sports Awards Administrator of the Year in 2015 and 2018, also a recipient of the gsport Ministerial Recognition Award in 2017 for her contribution to sports development and transformation. Rowing in SA is only offered at a select few schools (mostly private) and a handful of tertiary institutions. ",
      "There is still so much to be done for the sport, considering how inaccessible it is to so many, but as long as Virginia is involved, she hopes to change this, one kilometre at a time. Being on the road as much as she is, alone, away from her family, going to intimidating and unfamiliar places takes courage. Virginia believes that if you empower a woman in sports, you empower the nation. We couldn’t agree more.",
    ],
  },
]
