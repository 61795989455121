import React, { useEffect } from "react"
import styled from "styled-components"

import Documentary from "./Documentary"

const Container = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden !important;
`

const Home: React.FC<{}> = () => {
  useEffect(() => {
    function updateRealViewHeight() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      window.document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    updateRealViewHeight()
    window.addEventListener("resize", updateRealViewHeight)
    return (): void => {
      window.removeEventListener("resize", updateRealViewHeight)
    }
  }, [])

  return (
    <Container>
      <Documentary />
    </Container>
  )
}

export default Home
