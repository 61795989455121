import React, { useState } from "react"
import styled from "styled-components"
import playIcon from "../../images/icons/play.svg"

interface Props {
  icon?: any
  text: string
  onClick?: () => any
}

const Button: React.ForwardRefRenderFunction<any, Props> = (
  { icon, text, ...props }: Props,
  ref
) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <ButtonElement
      ref={ref}
      {...props}
      onMouseOver={(): void => setOnHover(true)}
      onMouseLeave={(): void => setOnHover(false)}
    >
      {icon && <Icon src={onHover ? playIcon : icon} />}
      <Text>{text}</Text>
    </ButtonElement>
  )
}

export default React.forwardRef<any, Props>(Button)

const ButtonElement = styled.button`
  border: 1px solid var(--gold);
  padding: 8px;
  text-transform: uppercase;
  color: var(--gold);
  vertical-align: middle;
  transition: all 0.25s ease;
  @media (min-width: 768px) {
    padding: 12px 25px;
    height: 60px;
    &:hover {
      cursor: pointer;
      background-color: var(--gold);
      span {
        color: #fff;
      }
    }
  }
`

const Text = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

const Icon = styled.img`
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
  @media (min-width: 768px) {
    margin-right: 15px;
  }
`
