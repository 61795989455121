import React from "react"
import Wrapper from "./Wrapper"

interface Props {
  color?: string
  width?: number
  height?: number
}

const LinkedIn: React.FC<Props> = ({
  color = "#ffffff",
  width = 50,
  height,
  ...props
}: Props) => {
  return (
    <Wrapper
      color={color}
      width={width}
      height={height}
      viewbox="0 0 288 283.82"
      {...props}
    >
      <g className="fill">
        <rect
          x="40.99"
          y="151.56"
          transform="matrix(1.263116e-03 -1 1 1.263116e-03 -79.7467 257.5132)"
          width="96.1"
          height="34.24"
        />
        <path
          d="M89.13,105.57c-10.67-0.01-19.34-8.7-19.32-19.35c0.01-10.65,8.71-19.31,19.37-19.3
		c10.66,0.01,19.32,8.69,19.31,19.34C108.48,96.93,99.79,105.58,89.13,105.57L89.13,105.57z"
        />
        <path
          d="M218.13,216.9l-34.19-0.04l0.06-46.82c0.02-14.01-1.68-20.68-11.36-20.69c-9.79-0.01-13.62,5.57-13.64,19.9
		l-0.06,47.57l-34.18-0.04l0.12-96.1l33.06,0.04l-0.01,6.8c5.27-4.95,13.25-9.01,23.68-9c32.29,0.04,36.61,23.26,36.58,45.98
		L218.13,216.9L218.13,216.9z"
        />
      </g>
    </Wrapper>
  )
}

export default LinkedIn
