const EPISODES = [
  {
    number: 1,
    title: "Courage Starts At Home",
    description:
      "There's something special about South Africa and the RMB National Squad.",
    shareMessage:
      "There’s something special about South Africa. Watch the rest of the story here: ",
    twitterShareMessage:
      "There’s something special about South Africa. Watch the rest of the story here: ",
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 0,
    shareTimeStart: 5,
    shareTimeEnd: 25,
    image: require("../images/episodes/01.jpg"),
  },
  {
    number: 2,
    title: "A Gutsy Performance",
    description:
      "20-year-old rower Thabelo Mashutha is on the cusp of Under-23. Does he have what it takes to row in the big league?",
    shareMessage:
      "You can trust Thabelo Mashutha to put his best out on the water every day. Watch the rest of his story here: ",
    twitterShareMessage:
      "You can trust Thabelo Mashutha to put his best out on the water every day. Watch the rest of his story here: ",
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 118,
    shareTimeStart: 120,
    shareTimeEnd: 130,
    image: require("../images/episodes/02.jpg"),
  },
  {
    number: 3,
    title: "Thrown in the Deep End",
    description:
      "Kirsten McCann was thrown in the deep end at just 19 years old to compete in her first Olympics. Now, she is a World Champion.",
    shareMessage:
      '"I don’t think I’d be here if I wasn’t striving for an Olympic gold.” – Kirsten McCann.',
    twitterShareMessage:
      '"I don’t think I’d be here if I wasn’t striving for an Olympic gold.” – Kirsten McDonald.',
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 226,
    shareTimeStart: 226,
    shareTimeEnd: 236,
    image: require("../images/episodes/03.jpg"),
  },
  {
    number: 4,
    title: "The World Stage",
    description:
      "Sizwe Ndlovu and his team brought home SA's first Olympic gold in rowing, which forced the world to take notice.",
    shareMessage:
      "Sizwe Ndlovu was the first black rower to win an Olympic gold in the world. Watch the rest of his story here: ",
    twitterShareMessage:
      "@SizweLNdlovu was the first black rower to win an Olympic gold in the world. Watch the rest of his story here: ",
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 334,
    shareTimeStart: 334,
    shareTimeEnd: 344,
    image: require("../images/episodes/04.jpg"),
  },
  {
    number: 5,
    title: "Rowing for Tomorrow",
    description:
      "School coach Lebohang Mashigo believes rowing can change South Africa for the better.",
    shareMessage:
      '"To beat countries that have ten times the money and athletes always feels amazing." – Lebohang Mashigo. Watch the rest of his story here: ',
    twitterShareMessage:
      '"To beat countries that have ten times the money and athletes always feels amazing." – Lebohang Mashigo. Watch the rest of his story here: ',
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 375,
    shareTimeStart: 375,
    shareTimeEnd: 385,
    image: require("../images/episodes/05.jpg"),
  },
  {
    number: 6,
    title: "Allegiance to the Green & Gold",
    description:
      "Head coach Roger Barrow turned down a once-in-a-lifetime job opportunity overseas, choosing Team SA instead.",
    shareMessage:
      "Roger Barrow fights for athletes to give them support. Watch the rest of his story here: ",
    twitterShareMessage:
      "@rogerbarrow fights for athletes to give them support. Watch the rest of his story here: ",
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 445,
    shareTimeStart: 445,
    shareTimeEnd: 455,
    image: require("../images/episodes/06.jpg"),
  },
  {
    number: 7,
    title: "Beating the Odds",
    description:
      "Heavyweight star rower Lawrence Brittain went from beating cancer to winning silver at the Olympics in just 18 months.",
    shareMessage:
      "Lawrence Brittain wasn’t fighting to be in the top boat for rowing, he was fighting for his life. Watch the rest of his story here: ",
    twitterShareMessage:
      "@Brittain_L wasn’t fighting to be in the top boat for rowing, he was fighting for his life. Watch the rest of his story here: ",
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 512,
    shareTimeStart: 512,
    shareTimeEnd: 522,
    image: require("../images/episodes/07.jpg"),
  },
  {
    number: 8,
    title: "Courage Pulls Us Together",
    description:
      "In challenging times, courage and perseverance pulls us together.",
    shareMessage:
      "When the Tokyo Olympics was postponed, the RMB National Squad put resilience into practice. Watch the rest of their story here: ",
    twitterShareMessage:
      "When the Tokyo Olympics was postponed, the RMB @NationalSquadZA put resilience into practice. Watch the rest of their story here: ",
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 677,
    shareTimeStart: 677,
    shareTimeEnd: 687,
    image: require("../images/episodes/08.jpg"),
  },
  {
    number: 9,
    title: "The Family You Choose",
    description:
      "Rowing in South Africa is more than a sport, it's a family bound by passion, determination and courage.",
    shareMessage:
      "There’s so much hidden talent in South Africa – if you can build the base at a young age, you can grow the talent pipeline for the future. Watch the rest of the story here: ",
    twitterShareMessage:
      "There’s so much hidden talent in South Africa – if you can build the base at a young age, you can grow the talent pipeline for the future. Watch the rest of the story here: ",
    hashtags: ["CourageCounts", "Rowing", "RMBNationalSquad"],
    time: 866,
    shareTimeStart: 866,
    shareTimeEnd: 876,
    image: require("../images/episodes/09.jpg"),
  },
]

export default EPISODES
