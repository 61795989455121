import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { isMobile } from "react-device-detect"

import illustration from "../../images/orientation.svg"

const Container = styled.div`
  position: fixed;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  img {
    max-width: 150px;
    margin-bottom: 1.5rem;
  }
  h4 {
    font-family: "RMB-Sans Display";
    text-transform: uppercase;
    color: white;
    letter-spacing: 1px;
    font-weight: 700;
    text-align: center;
  }
  &.active {
    /* Put this below line back should we need to add a close button */
    /* pointer-events: all; */
    opacity: 1;
  }
  @media (min-width: 768px) {
    img {
      max-width: 200px;
    }
  }
`

const OrientationPopUp: React.FC<{}> = () => {
  const [isLandscape, setLandscape] = useState(false)

  const checkOrientation = (): void => {
    if (window.orientation !== 0) {
      setLandscape(true)
    } else {
      setLandscape(false)
    }
  }

  useEffect(() => {
    if (isMobile) {
      checkOrientation()
      window.addEventListener("orientationchange", checkOrientation)
    }
    return (): void => {
      if (isMobile) {
        window.removeEventListener("orientationchange", checkOrientation)
      }
    }
  }, [])
  return (
    <Container className={isLandscape ? "active" : ""}>
      <img src={illustration} alt="RMB" />
      <h4>This site is best experienced in portrait</h4>
    </Container>
  )
}

export default OrientationPopUp

/*

  README:

  window.width. > window.height
  window.screen.availWidth > window.screen.availHeight
  window.screen.orientation

  theese checks does not seem to work on actual devices and only on simulators

*/
